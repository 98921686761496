'use client';

import PaymentLoading from '@/components/atoms/Loaders/PaymentLoading';
import LabTestOrderSuccess from '@/labtest/presentation/organisms/OrderSuccessModal/LabTestOrderSuccess';
import useToggle from '@labHooks/use-toggle';
import useLabCartContext from '@labServices/context/LabCartContext';
import Button from '@labtest/presentation/atoms/Button';
import Line from '@labtest/presentation/atoms/Line';
import DialogModal from '@labtest/presentation/atoms/Modal/DialogModal';
import LabTestPaymentDetailsCard from '@labtest/presentation/molecules/LabTestPaymentDetailsCard';
import PaymentMethodInformation from '@labtest/presentation/molecules/PaymentMethodInfo';
import lottie from 'lottie-web';
import animated_graphic from './data.json';
// import ConsultPatientProblemsCard from '@/doc-consultation/presentation/molecules/DoctorPortal/ConsultPatientProblemsCard';
// import PaymentDetailsCard from '@/doc-consultation/presentation/molecules/PatientPaymentInfo/PaymentDetailsCard';
// import PaymentMethodInformation from '@/doc-consultation/presentation/molecules/PatientPaymentInfo/PaymentMethodInfo';
// import EditPatientConsultationReason from '@/doc-consultation/presentation/organisms/MyConsultationDetails/EditPatientConsultationReason';
// import DocConsultationOrderSuccess from './DocConsultationOrderSuccess';

import { getLabTestsOrderDetails, onlinePaymentForLabOrder } from '@labServices/apis/lab-test-apis/order';
import { monitorPopupWindow } from '@labServices/utils/cmnHelper';
import { useRouter } from 'next/navigation';
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { FaCircleCheck, FaRegCircleDot } from 'react-icons/fa6';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { toast } from 'react-toastify';

// import { BookingPlacedSVG, PayOnlineSVG } from '@/services/doc-consultation/utils/iconBase';
// import {
// 	getDocConsultationDetails,
// 	onlinePaymentForConsultation
// } from '@/services/doc-consultation/apis/doc-consultation-apis/doc-consultation';
// import { monitorPopupWindow } from '@/services/lab-test/utils/cmnHelper';
// import { toast } from 'react-toastify';

import type { IG_LT_OrderDetails } from '@labServices/apis/types/lab-order/type.getOrderDetails';
// import type { IG_DoctorConsultationDetails } from '@/services/doc-consultation/apis/doc-consultation-apis/types/doc-consultation/type.getDocConsultationDetails';
// import type { IG_CheckoutDetails } from '@/services/doc-consultation/apis/doc-consultation-apis/types/doc-consultation/type.getDocConsultationCheckout';
// import type { IG_DoctorConsultationPaymentInfo } from '@/services/doc-consultation/apis/doc-consultation-apis/types/doc-consultation/type.getDocConsultationPaymentInfo';

import styles from './OrderSuccessModal.module.scss';

interface I_Props {
	serviceType: 'lab-test' | 'doc-consultation';
	data: Partial<IG_LT_OrderDetails>;
	setLabOrderDetails?: Dispatch<SetStateAction<Partial<IG_LT_OrderDetails>>>;
	// data: Partial<IG_DoctorConsultationPaymentInfo>;
	// checkoutData?: Partial<IG_CheckoutDetails>;
	// setCheckoutData?: React.Dispatch<React.SetStateAction<Partial<IG_CheckoutDetails>>>;
	title: string;
	message: React.ReactElement | string;
	handleCloseOrderSuccessModal: () => void;
	handleOpenOrderSuccessModal: () => void;
	handleOpenPaymentStatusModal?: () => void;
	handleClosePaymentStatusModal?: () => void;
	// closeCheckoutModal?: () => void;
}

const OrderSuccessModal = ({
	serviceType,
	data,
	setLabOrderDetails,
	// checkoutData,
	// setCheckoutData,
	title,
	message,
	handleCloseOrderSuccessModal,
	handleOpenOrderSuccessModal,
	handleOpenPaymentStatusModal,
	handleClosePaymentStatusModal
	// closeCheckoutModal
}: I_Props) => {
	// Lab Test
	const { setCartData } = useLabCartContext();
	const [paymentProgress, openPaymentProgressModal, closePaymentProgressModal] = useToggle();

	// Doctor Consultation
	const [viewDetails, setViewDetails] = useState<boolean>(false);
	const [isBookedAppointment, setIsBookedAppointment] = useState<boolean>(false);
	const [isEdit, setIsEdit] = useState<boolean>(true);
	// const [consultationDetails, setConsultationDetails] = useState<Partial<IG_DoctorConsultationDetails>>({});
	const [isSaveInfo, setIsSaveInfo] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const router = useRouter();
	const editInfo = useRef();

	const handlerViewLabOrderDetails = () => {
		handleCloseOrderSuccessModal();

		router.push(`/account/lab-test/orders/${data?.id}`);
	};

	const handleStartNewOrder = () => {
		handleCloseOrderSuccessModal();
		router.push('/lab-test/tests');
	};

	const handlerLabTestOnlinePayment = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.preventDefault();

		const res = await onlinePaymentForLabOrder(data?.id);

		if (res?.success) {
			openPaymentProgressModal();

			monitorPopupWindow(`${res?.data?.paymentUrl}`, 'Arogga Payment', window, 650, 700, async () => {
				handleCloseOrderSuccessModal();
				closePaymentProgressModal();
				const orderDetailsRes = await getLabTestsOrderDetails(data?.id);

				if (res?.success) {
					setLabOrderDetails((prev) => ({ ...prev, ...orderDetailsRes?.data }));
					handleOpenPaymentStatusModal && handleOpenPaymentStatusModal();
				}

				// setcartTotalPriceItems({
				// 	totalAmount: 0,
				// 	itemCount: 0
				// });
				// // handleGetLabCartData();
				// setCartData((prev) => ({
				// 	...prev,
				// 	items: [],
				// 	totalAmount: '0',
				// 	itemCount: 0
				// }));
				// setItemInLocalStorage(cartStorageName, {});
			});
		} else {
			toast.error(res?.message);
			// handleGetConsultationDetails();
		}
	};

	// const handleGetConsultationDetails = async () => {
	// 	setIsLoading(true);
	// 	const res = await getDocConsultationDetails(data?.id);

	// 	if (res?.success) {
	// 		setConsultationDetails(res?.data);

	// 		setIsEdit(false);

	// 		// Change Problem Details Card to Edit Mode if consultationReason is not provided
	// 		// if (!res?.data?.consultationReason) {
	// 		// 	setIsEdit(true);
	// 		// }
	// 	}

	// 	setIsLoading(false);
	// };

	// const handleSaveAdditionalInfo = () => {
	// 	setIsSaveInfo(true);

	// 	if (editInfo.current) {
	// 		editInfo.current.triggerSave();
	// 	}
	// };

	// const handlerPayOnline = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
	// 	e.preventDefault();

	// 	const res = await onlinePaymentForConsultation(data?.id);
	// 	if (res?.success) {
	// 		monitorPopupWindow(`${res?.data?.paymentUrl}`, 'Arogga Payment', window, 650, 700, () => {
	// 			// handleGetConsultationDetails();
	// 			// router.push(`/account/doctorConsultation/my-consultations/${consultationDetails?.id}`);
	// 			setIsBookedAppointment(true);
	// 		});
	// 	} else {
	// 		toast.error(res?.message);
	// 		// handleGetConsultationDetails();
	// 	}
	// };

	useEffect(() => {
		if (typeof window !== 'undefined' && typeof document !== 'undefined') {
			const animation = lottie.loadAnimation({
				loop: true,
				renderer: 'svg',
				animationData: animated_graphic,
				container: document.getElementById('animated_graphic')
			});
			return () => animation.destroy();
		}
	}, []);

	useEffect(() => {
		if (data?.paymentStatus === 'paid') {
			setViewDetails(true);
		}
	}, [data?.paymentStatus]);

	return (
		<>
			<div className={styles.container}>
				<div className={styles.details_container}>
					<div className={styles.icon_container_wrap}>
						<div className={styles.order_success_graphic} id='animated_graphic'></div>

						<div className='flex-column items-center justify-center gap-4 mb-20'>
							<div className='ltbody_xlg__semibold ltGreyscale900'>{title}</div>
							<div className='ltbody_md__regular ltGreyscale600'>{message}</div>
						</div>

						<div className={styles.booking_track_icons}>
							<div className={styles.track_icon}>
								{/* <BookingPlacedSVG /> */}
								<FaCircleCheck
									size={20}
									color='var(--ltSuccessDark)'
									style={{
										minWidth: '20px'
									}}
								/>

								<div className='w-full'>
									<Line
										height='h-4'
										color={data?.paymentStatus === 'paid' ? 'bgltSuccessDark' : 'bgltGreyscale400'}
									/>
								</div>
								{data?.paymentStatus === 'paid' ? (
									<FaCircleCheck
										size={20}
										color='var(--ltSuccessDark)'
										style={{
											minWidth: '20px'
										}}
									/>
								) : (
									<FaRegCircleDot
										size={20}
										color='var(--ltGreyscale400)'
										style={{
											minWidth: '20px'
										}}
									/>
								)}

								{/* <PayOnlineSVG /> */}
							</div>

							<div className={styles.track_icon_text}>
								<p className='ltbody_md__regular ltSuccessDark t_nowrap'>
									{serviceType === 'lab-test'
										? 'Order Placed'
										: // Doctor Consultation
											data?.paymentStatus === 'paid'
											? 'Paid'
											: 'Booking Placed'}
								</p>
								<p
									className={`${data?.paymentStatus === 'paid' ? 'ltSuccessDark' : 'ltGreyscale600'} ltbody_md__regular t_nowrap`}>
									{data?.paymentStatus === 'paid' ? 'Paid' : 'Pay Online'}
								</p>
							</div>
						</div>
					</div>

					{viewDetails && (
						<>
							{serviceType === 'lab-test' && <LabTestOrderSuccess data={data} />}

							{/* {serviceType === 'doc-consultation' && (
								<DocConsultationOrderSuccess data={data} closeOrderSuccessModal={closeOrderSuccessModal} />
							)} */}
						</>
					)}

					<div
						className={`${styles.view_details_btn} flex gap-4 items-center justify-center ltbody_md__medium primaryBase pb-20`}
						onClick={() => setViewDetails(!viewDetails)}>
						<p>View details</p> {viewDetails ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
					</div>
				</div>

				{serviceType === 'lab-test' && (
					<>
						{data?.paymentStatus === 'unpaid' && (
							<>
								<div className={styles.payment_container}>
									<PaymentMethodInformation />
								</div>
								<div className={styles.lab_payment_container}>
									<LabTestPaymentDetailsCard data={data} page='modal' />
								</div>
							</>
						)}
					</>
				)}

				{/* 	{serviceType === 'doc-consultation' && isBookedAppointment ? (
					<>
						{isEdit ? (
							<EditPatientConsultationReason
								ref={editInfo}
								consultation={data}
								consultationId={data?.id}
								handleGetConsultationDetails={handleGetConsultationDetails}
								isEdit={isEdit}
								// setIsEdit={setIsEdit}
								// setIsSaveInfo={setIsSaveInfo}
								page='order_success_modal'
							/>
						) : (
							<ConsultPatientProblemsCard
								data={consultationDetails}
								isEdit={isEdit}
								setIsEdit={setIsEdit}
								setIsSaveInfo={setIsSaveInfo}
								type='doctor'
							/>
						)}
					</>
				) : (
					<>
						<div className={styles.payment_container}>
							<PaymentMethodInformation />
						</div>
						<div className={styles.payment_container}>
							<PaymentDetailsCard data={data} page='order_success_modal' />
						</div>
					</>
				)} */}
			</div>

			<div className={`${styles.button_container} ${styles.button_container_orderCancel}`}>
				{/* {serviceType === 'doc-consultation' && (
					<>
						{isBookedAppointment ? (
							<>
								{isSaveInfo ? (
									<Button
										fullWidth
										onClick={() => router.push(`/account/doctor-consultation/my-consultations/${data?.id}`)}>
										View Details
									</Button>
								) : (
									<Button fullWidth onClick={handleSaveAdditionalInfo}>
										Save Now
									</Button>
								)}
								<div className='flex gap-20 fullWidth'>
									<Button
										variant={isSaveInfo ? 'bgWhitePrimary' : 'disabled'}
										color={isSaveInfo && 'clGrey900'}
										fullWidth
										onClick={() =>
											// isSaveInfo ? setIsEdit(false) : toast.error('Consultation Reason must be provided')
											isSaveInfo && setIsEdit(false)
										}>
										Connect with Doctor
									</Button>
								</div>
							</>
						) : (
							<Button fullWidth onClick={handlerPayOnline}>
								Pay online: ৳{data?.totalAmount}
							</Button>
						)}
					</>
				)} */}

				{serviceType === 'lab-test' && (
					<>
						{data?.paymentStatus === 'unpaid' && (
							<Button variant='bgPrimary' fullWidth onClick={handlerLabTestOnlinePayment}>
								<>Pay online: ৳{data?.totalAmount}</>
							</Button>
						)}

						{data?.paymentMethod === 'online' && data?.paymentStatus === 'paid' && (
							<Button variant='bgPrimary' fullWidth onClick={handleStartNewOrder}>
								Start A New Order
							</Button>
						)}

						<Button variant='bgWhitePrimary' color='clPrimary' fullWidth onClick={handlerViewLabOrderDetails}>
							View Order Details
						</Button>
					</>
				)}
			</div>

			{paymentProgress && (
				<DialogModal open={paymentProgress} closeModal={closePaymentProgressModal} overflow='overflow_scroll_y'>
					<PaymentLoading />
				</DialogModal>
			)}
		</>
	);
};

export default OrderSuccessModal;
