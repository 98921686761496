'use client';

import CouponCodeInput from '@/labtest/presentation/atoms/CouponCodeInput';
import useLabCartContext from '@labServices/context/LabCartContext';

import { cartStorageName } from '@/services/ecommerce/apis/config';
import { applyDiscountCoupon } from '@labServices/apis/lab-test-apis/cart';
import { setItemInLocalStorage } from '@labServices/utils/session';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import styles from './LabPromoSectionCard.module.scss';

interface I_Props {}

const LabPromoSectionCard = ({}: I_Props) => {
	const { cartData, setCartData, couponCode, setCouponCode } = useLabCartContext();
	const [showCouponCodeInput, setShowCouponCodeInput] = useState<boolean>(false);
	const [isCouponCodeApplied, setIsCouponCodeApplied] = useState<boolean>(false);

	const handleCouponCode = async (code) => {
		const res = await applyDiscountCoupon(code);

		if (res?.success) {
			setIsCouponCodeApplied(true);
			setCartData((prev) => ({ ...prev, ...res?.data }));
			setItemInLocalStorage(cartStorageName, res?.data);
		} else {
			setIsCouponCodeApplied(false);
			toast.error(res?.message);
		}
	};

	const handelResetCouponCode = async () => {
		setCouponCode('');
		setIsCouponCodeApplied(false);

		const res = await applyDiscountCoupon('');

		if (res?.success) {
			setCartData((prev) => ({ ...prev, ...res?.data }));
			setItemInLocalStorage(cartStorageName, res?.data);
		} else {
			toast.error(res?.message);
		}
	};

	useEffect(() => {
		if (!!cartData?.promoCode) {
			setCouponCode(cartData?.promoCode || '');
			setIsCouponCodeApplied(true);
		} else {
			setShowCouponCodeInput(false);
			setIsCouponCodeApplied(false);
			setCouponCode('');
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cartData?.promoCode]);

	return (
		<div className={styles.promoSecCard}>
			<div
				className='ltbody_md__medium  primaryBase text_underline  mb-15'
				onClick={() => setShowCouponCodeInput(!showCouponCodeInput)}>
				Have coupon code ?
			</div>
			{(cartData?.promoCode || showCouponCodeInput) && (
				<div className='mt-10'>
					<CouponCodeInput
						onClickApply={handleCouponCode}
						onClickReset={handelResetCouponCode}
						applyButtonText={cartData?.promoCode || isCouponCodeApplied ? 'Remove' : 'Apply'}
						isCouponCodeApplied={isCouponCodeApplied}
						couponCode={couponCode}
						setCouponCode={setCouponCode}
					/>
				</div>
			)}
		</div>
	);
};

export default LabPromoSectionCard;
