import { useEffect } from 'react';
import styles from './Modal.module.scss';

interface I_Props {
	open: boolean;
	children: JSX.Element;
	closeModal: () => void;
	overflow?: 'overflow_scroll_x' | 'overflow_scroll_y' | 'overflow_hidden' | 'overflow_auto' | 'overflow_visible';
	bodyOverflow?: 'overflow_scroll_x' | 'overflow_scroll_y' | 'overflow_hidden' | 'overflow_auto' | 'overflow_visible';
}
const StateFullModal = ({ open, children, closeModal, overflow, bodyOverflow }: I_Props) => {
	// useOutsideClick(ref, closeModal);
	useEffect(() => {
		if (typeof window === 'undefined') return;
		if (open) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'auto';
		}
		return () => {
			document.body.style.overflow = 'auto';
		};
	}, [open]);

	return (
		<div
			className={`${styles.stateFullModal} ${overflow} ${open ? styles.open : ''}`}
			onClick={(e) => e.stopPropagation()}>
			<div className={`${styles.stateFullModal_body} ${bodyOverflow}`}>{children}</div>
		</div>
	);
};

export default StateFullModal;
