import { BiSolidOffer } from 'react-icons/bi';

import styles from './CouponCodeInput.module.scss';

interface I_Props {
	couponCode: string;
	setCouponCode: (value: string) => void;
	onClickApply: (couponCode: string) => void;
	onClickReset: () => void;
	isCouponCodeApplied: boolean;
	componentType?: 'dr-consultation' | 'lab-test';
	applyButtonText?: string;
}

const CouponCodeInput = ({
	couponCode,
	setCouponCode,
	onClickApply,
	onClickReset,
	isCouponCodeApplied,
	componentType,
	applyButtonText = 'Apply'
}: I_Props) => {
	return (
		<div className={`${styles.container} ${componentType === 'dr-consultation' ? styles.dr_container : ''}`}>
			<div className={styles.iconContainer}>
				<BiSolidOffer size={20} className={styles.icon} />
			</div>
			<input
				className={styles.input}
				type='text'
				placeholder='Enter Coupon Code'
				value={couponCode}
				disabled={isCouponCodeApplied}
				onChange={(e) => setCouponCode(e.target.value)}
			/>
			<button
				className={`${styles.button} t_nowrap`}
				disabled={(applyButtonText === 'Apply' || applyButtonText === 'Apply Now') && !couponCode}
				style={{
					backgroundColor: applyButtonText === 'Remove' && 'red'
				}}
				onClick={
					applyButtonText === 'Apply' || applyButtonText === 'Apply Now'
						? () => onClickApply(couponCode)
						: onClickReset
				}>
				{applyButtonText}
			</button>
		</div>
	);
};

export default CouponCodeInput;
