'use client';

import useOutsideClick from '@labServices/hooks/useOutsideClick';

import { useRef } from 'react';

import styles from './Popover.module.scss';

interface I_Props {
	variant?: 'primary' | 'secondary' | 'danger';
	open: boolean | string;
	handleClose: () => void;
	children: any;
	position?: 't_60__r_0' | 't_n260__r_0' | 't_n330__r_0' | 't_n190__r_0';
	className?: string;
}

const Popover = ({ variant, open, handleClose, children, position, className }: I_Props) => {
	const ref = useRef();

	useOutsideClick(ref, handleClose);

	return (
		<div
			ref={ref}
			className={`
		${styles.popover} 
		${variant ? styles[variant] : ''} 
		${position ? styles[position] : ''}
		${open ? styles.open : ''} ${className}`}>
			{children}
		</div>
	);
};

export default Popover;
