import { MdCheckCircle } from 'react-icons/md';

import type { MouseEvent } from 'react';

import styles from './BookforItem.module.scss';

interface I_Props {
	onClick: (e: MouseEvent<HTMLDivElement>) => void;
	checked?: boolean;
	children: React.ReactNode;
}

const BookforItem = ({ onClick, checked, children }: I_Props) => {
	return (
		<div onClick={onClick} className={`${styles.paitent_num} ${checked ? styles.checked : ''} ltGreyscale900`}>
			{children}
			{checked ? <MdCheckCircle size={24} color='var(--ltPrimary)' /> : ''}
		</div>
	);
};

export default BookforItem;
