import { Alarm, AutoGraph, MarkEmailRead, Science } from '@labServices/utils/svg';
import { MdOutlineShoppingBasket } from 'react-icons/md';

import type { IG_LT_OrderDetails } from '@labServices/apis/types/lab-order/type.getOrderDetails';

import styles from './LabTestOrderSuccess.module.scss';

interface I_Props {
	data: Partial<IG_LT_OrderDetails>;
}

const LabTestOrderSuccess = ({ data }: I_Props) => {
	return (
		<>
			<div className={styles.order_details_container}>
				<div className='flex-column gap-8'>
					{/* Order Id */}
					<div className={styles.order_id}>
						<AutoGraph />
						<div className='flex-column ltbody_md__medium'>
							<div>Order ID</div>
							<p>#{data?.orderNumber}</p>
						</div>
					</div>
					{/* Total Order Amount */}
					<div className={styles.order_item}>
						<MdOutlineShoppingBasket size={24} />

						<div className={styles.order_item_info}>
							<div>Total Order Amount</div>

							<p>৳{data?.totalAmount}</p>
						</div>
					</div>
					{/* Order Item */}
					<div className={styles.order_item}>
						<Science />

						<div className={styles.order_item_info}>
							<div>Order Item</div>

							{(data?.packageCount > 0 || data?.testCount > 0) && (
								<p>
									{data?.packageCount && data?.packageCount > 0
										? `${data?.packageCount} ${data?.packageCount > 1 ? 'Packages' : 'Package'}`
										: ''}
									{data?.packageCount && data?.testCount ? ' & ' : ''}
									{data?.testCount && data?.testCount > 0
										? `${data?.testCount} ${data?.testCount > 1 ? 'Tests' : 'Test'}`
										: ''}
									{/* {' for '} */}
									{/* {data?.items?.reduce((previous, current) => previous + current.patientCount, 0)}{' '}
									{data?.items?.reduce((previous, current) => previous + current.patientCount, 0) > 1
										? 'Patients'
										: 'Patient'} */}
								</p>
							)}
						</div>
					</div>
					{/* Sample Collection Schedule */}
					<div className={styles.order_sample_collection}>
						<Alarm />

						<div className={styles.order_sample_collection_info}>
							<div>Sample Collection Schedule</div>
							<p>
								{data?.formattedScheduleDate} ({data?.scheduleTimeRange?.en})
							</p>
						</div>
					</div>
					{/* Confirmation Sms */}
					<div className={styles.order_confirmation}>
						<MarkEmailRead />

						<div className={styles.order_confirmation_info}>
							<div>Confirmation will be sent to your Arogga App, SMS</div>
							<p>Check your Arogga App or Phone SMS</p>
						</div>
					</div>
				</div>
			</div>

			{/* <div className={`${styles.button_container} ${styles.button_container_orderCancel}`}>
				<Button variant='bgOrange_01' color='clOrange' fullWidth onClick={() => handlerViewOrderDetails()}>
					View order details
				</Button>
				<Button variant='bgPrimary' fullWidth onClick={() => handlerCreateNewOrder()}>
					Create new order
				</Button>
			</div> */}
		</>
	);
};

export default LabTestOrderSuccess;
