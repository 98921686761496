import Line from '@/labtest/presentation/atoms/Line';
import LabPromoSectionCard from '../LabPromoSectionCard';

import type { IG_LT_TestCartData } from '@labServices/apis/types/lab-cart/type.getTestCartData';
import type { IG_LT_OrderDetails } from '@labServices/apis/types/lab-order/type.getOrderDetails';

import styles from './LabTestPaymentDetailsCard.module.scss';

export interface I_Props {
	data: Partial<IG_LT_OrderDetails> | Partial<IG_LT_TestCartData>;
	page?: 'modal' | 'order-details' | 'cart';
}

const LabTestPaymentDetailsCard = ({ data, page }: I_Props) => {
	return (
		<div className={`${styles.paymentDetailsCard} ${page}`}>
			{page !== 'modal' && page !== 'order-details' && <LabPromoSectionCard />}
			<div
				className={`flex-column ${page === 'cart' ? 'gap-10 py-10' : page === 'modal' ? 'gap-10 py-10' : 'gap-16 py-20'}`}>
				<div
					className={`flex-column ${page === 'cart' ? 'gap-10 px-12' : page === 'modal' ? 'gap-10 px-20' : 'gap-20 px-20'}`}>
					{/* Subtotal  */}
					<div className='flex justify-between'>
						<p className={`${page === 'cart' ? 'ltbody_sm__medium' : 'ltbody_lg__regular'} ltGreyscale900`}>
							Subtotal
						</p>
						<p className={`${page === 'cart' ? 'ltbody_sm__medium' : 'ltbody_lg__regular'} ltGreyscale900`}>
							৳{data?.subtotalAmount}
						</p>
					</div>
					{/* Discount Applied */}
					<div className='flex justify-between'>
						<p className={`${page === 'cart' ? 'ltbody_sm__medium' : 'ltbody_lg__regular'} ltGreyscale900`}>
							Discount Applied
						</p>
						<p className={`${page === 'cart' ? 'ltbody_sm__medium' : 'ltbody_lg__regular'} primaryBase`}>
							{Number(data?.priceDiscount) > 0 ? `-৳${data?.priceDiscount}` : `৳${data?.priceDiscount}`}
						</p>
					</div>
					{/* Coupon Applied */}
					{Number(data?.promoDiscount) > 0 && data?.promoCode && (
						<div className='flex justify-between'>
							<p className={`${page === 'cart' ? 'ltbody_sm__medium' : 'ltbody_lg__regular'} ltGreyscale900`}>
								Coupon Applied ({data?.promoCode})
							</p>
							<p className={`${page === 'cart' ? 'ltbody_sm__medium' : 'ltbody_lg__regular'} primaryBase`}>
								-৳{data?.promoDiscount}
							</p>
						</div>
					)}

					{/* Rounding Off */}
					{Number(data?.roundingOff) > 0 && (
						<div className='flex justify-between'>
							<p className={`${page === 'cart' ? 'ltbody_sm__medium' : 'ltbody_lg__regular'} ltGreyscale900`}>
								Rounding Off
							</p>
							<p className={`${page === 'cart' ? 'ltbody_sm__medium' : 'ltbody_lg__regular'} primaryBase`}>
								-৳{data?.roundingOff}
							</p>
						</div>
					)}
				</div>

				<Line />

				<div
					className={`flex-column ${page === 'cart' ? 'gap-10 px-12' : page === 'modal' ? 'gap-10 px-20' : 'gap-20 px-20'}`}>
					{/* Hard Copy Conveyance  */}
					{data?.isHardCopyRequired && (
						<div className='flex justify-between'>
							<p className={`${page === 'cart' ? 'ltbody_sm__medium' : 'ltbody_lg__regular'} ltGreyscale900`}>
								Hard Copy Conveyance
							</p>
							{page === 'cart' ? (
								<p className={`${page === 'cart' ? 'ltbody_sm__medium' : 'ltbody_lg__regular'} errorBase`}>
									{Number(data?.hardCopyConveyance) === 0 ? (
										'৳0'
									) : (
										<>
											{'shouldApplyHardCopyConveyance' in data && data?.shouldApplyHardCopyConveyance ? (
												<span className={styles.sample_collection_fee_container}>
													৳{data?.hardCopyConveyance}
												</span>
											) : (
												<>
													<span className='text_line_through'>৳{data?.hardCopyConveyance}</span>
													<span className={styles.text_line_through}>৳0</span>
												</>
											)}
										</>
									)}
								</p>
							) : (
								<p className='ltbody_lg__regular errorBase'>
									<span className={styles.sample_collection_fee_container}>৳{data?.hardCopyConveyance}</span>
								</p>
							)}
						</div>
					)}

					{/* Sample Collection Conveyance  */}
					<div className='flex justify-between'>
						<p className={`${page === 'cart' ? 'ltbody_sm__medium' : 'ltbody_lg__regular'} ltGreyscale900`}>
							Sample Collection Conveyance
						</p>
						<p className={`${page === 'cart' ? 'ltbody_sm__medium' : 'ltbody_lg__regular'} errorBase`}>
							<span className={styles.sample_collection_fee_container}>৳{data?.collectionConveyance}</span>
						</p>
					</div>

					{/* Material Charge */}
					{'labMaterialCharge' in data && Number(data?.labMaterialCharge) > 0 && (
						<div className='flex justify-between'>
							<p className={`${page === 'cart' ? 'ltbody_sm__medium' : 'ltbody_lg__regular'} ltGreyscale700`}>
								Material Charge
							</p>
							<p className={`${page === 'cart' ? 'ltbody_sm__medium' : 'ltbody_lg__regular'} errorBase`}>
								৳{Number(data?.labMaterialCharge)}
							</p>
						</div>
					)}
				</div>
				<Line />

				<div
					className={`flex-column ${page === 'cart' ? 'gap-10 px-12' : page === 'modal' ? 'gap-10 px-20' : 'gap-20 px-20'}`}>
					{/* Arogga Cash Applied */}
					<div className='flex  justify-between'>
						<p className={`${page === 'cart' ? 'ltbody_sm__medium' : 'ltbody_lg__regular'} ltGreyscale900`}>
							Arogga Cash Applied
						</p>
						<p className={`${page === 'cart' ? 'ltbody_sm__medium' : 'ltbody_lg__regular'} primaryBase`}>
							{'cashWithoutConveyance' in data
								? data?.isHardCopyRequired
									? Number(data?.cash) > 0
										? `-৳${data?.cash}`
										: `৳${data?.cash}`
									: Number(data?.cashWithoutConveyance) > 0
										? `-৳${data?.cashWithoutConveyance}`
										: `৳${data?.cashWithoutConveyance}`
								: Number(data?.cash) > 0
									? `-৳${data?.cash}`
									: `৳${data?.cash}`}
						</p>
					</div>
				</div>
			</div>
			<Line dashed='dashed' />
			<div
				className={`flex-column ${page === 'cart' ? 'gap-10 px-12 py-10' : page === 'modal' ? 'gap-10 px-20 py-10' : 'gap-20 p-20'}`}>
				{/* Total Payable  */}
				<div className='flex justify-between'>
					<p className={`${page === 'cart' ? 'ltbody_sm__medium ' : 'ltbody_lg__medium'} ltGreyscale900`}>
						Total Payable
					</p>

					<p className={`${page === 'cart' ? 'ltbody_sm__medium' : 'ltbody_lg__medium'} ltGreyscale900`}>
						{'totalAmountWithoutConveyance' in data
							? data?.isHardCopyRequired
								? `৳${data?.totalAmount}`
								: `৳${data?.totalAmountWithoutConveyance}`
							: `৳${data?.totalAmount}`}
					</p>
				</div>

				{/* ================ Order Details ============== */}

				{/* You Paid */}
				{'paymentStatus' in data && data?.paymentStatus === 'paid' && (
					<div className='flex justify-between'>
						<p className='ltbody_lg__medium'>
							<span className='ltSuccessDark mr5'>You Paid</span>
							<span className='ltGreyscale600'>
								{data?.paymentMethod === 'cod' ? '(COD)' : '(Online Payment)'}
							</span>
						</p>
						<p className='ltbody_lg__medium  '>৳{data?.paidAmount}</p>
					</div>
				)}

				{/* Refund */}
				{'refundAmount' in data && (Number(data?.refundAmount) > 0 || Number(data?.refundCash) > 0) && (
					<div className='flex-column gap-16'>
						<div className='flex justify-between'>
							<p className='ltbody_lg__regular ltGreyscale700'>Arogga Cash Refund</p>
							<p className='ltbody_lg__regular primaryBase'>৳{data?.refundCash}</p>
						</div>
						<div className='flex justify-between'>
							<p className='ltbody_lg__regular ltGreyscale700'>Payment Refund</p>
							<p className='ltbody_lg__regular primaryBase'>৳{data?.refundAmount}</p>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default LabTestPaymentDetailsCard;
