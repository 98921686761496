'use client';

import { useEffect, useRef } from 'react';
import styles from './Modal.module.scss';

interface I_Props {
	name?: string;
	overflow?: 'overflow_scroll_x' | 'overflow_scroll_y' | 'overflow_hidden' | 'overflow_auto' | 'overflow_visible';
	bodyOverflow?: 'overflow_scroll_x' | 'overflow_scroll_y' | 'overflow_hidden' | 'overflow_auto' | 'overflow_visible';
	open: boolean;
	closeModal: () => void;
	children: JSX.Element;
}

const DialogModal = ({ name, open, closeModal, children, overflow, bodyOverflow }: I_Props) => {
	const ref = useRef<HTMLDialogElement | null>(null);

	useEffect(() => {
		if (open) {
			if (ref.current) {
				ref.current.showModal();
			}
		} else {
			ref.current?.close();
		}
	}, [open]);

	useEffect(() => {
		if (typeof window === 'undefined') return;
		if (open) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'auto';
		}

		return () => {
			document.body.style.overflow = 'auto';
		};
	}, [open]);

	return (
		<dialog
			ref={ref}
			className={`${styles.dialogModal} ${overflow} `}
			onClick={(e) => e.stopPropagation()}
			onCancel={closeModal}>
			<div className={`${styles.dialogModal_body} ${styles[`_${name}`]} ${bodyOverflow}`}>{children}</div>
		</dialog>
	);
};

// const Modal = forwardRef(ModalWrap);
export default DialogModal;
